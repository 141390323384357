import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import {consulting} from '../components/strings'
import akern from "../images/partners/akern.png"
import coche_box from "../images/partners/coche_box.png"
import sarayresidence from "../images/partners/sarayresidence.png"
import photoschweda from "../images/partners/photoschweda.png"
import swiss from "../images/partners/swiss_jewellery.png"
import modroizeleno from "../images/partners/modroizeleno.png"
import kupitehniku from "../images/partners/kupitehniku.png"
import labconsulting from "../images/partners/labconsulting.png"
import bauwach from "../images/partners/bauwach.png"
import zeljo from "../images/partners/zeljo.png"
import AOS from 'aos';
import Helmet from 'react-helmet'

 class ConsultingPage extends React.Component {
    componentDidMount(){
        AOS.init({
          duration : 500
        })
      }
  render() {

    return (
      <Layout>
           <Helmet
          title="WELL – Consulting"
          meta={[
            { name: 'description', content: 'Allways WELL done - simple to most demanding websites, online stores and applications, SEO, modern methods of advertising through Google and social media.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
          ]} >
        </Helmet>
          <div id="consulting">
          <Header prefix_string_page="en"/>
            <div data-aos='fade-zoom-in'>
            
            <section id="p_consulting" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h1 className="title">{consulting}</h1>

                <div className="content">
    <p><strong>
    There are very few companies that have managed to develop a successful business without professional advice. For this purpose, consultants who objectively analyze your situation are engaged and based on the results of the analysis, they advise you on current and future business.
    </strong></p>
    <p>We are often confronted with companies with unsatisfactory effect of our own marketing campaigns. They invested a "ton" of money and did not see the effect.
Already after a brief analysis of the business of these companies and the review of the previous marketing campaigns, it is immediately found that the basic prerequisites for conducting marketing campaigns are not fulfilled, such as:</p>
<ul>
    <li>defining a clear corporate identity,</li>
    <li>analysis of the market, competition and target group, </li>
    <li>defining benefits that your products or services provide to potential buyers, </li>
    <li>defining your strengths in relation to competition,</li>
    <li>aligning financial possibilities with the scope of marketing campaigns, etc.</li>
</ul>
<p>The same assumptions apply to digital marketing. Without a thorough analysis of the client's business, it is virtually impossible to do quality digital marketing.
A good website, online store, quality digital marketing campaigns (Google AdWords, Facebook, Instagram, LinkedIn, Xing ...) must be designed in advance according to the specifics of your business and tailor it to your goals and objective opportunities.
These are all things that are previously defined through consulting.</p>

<h3>When to hire a consultant?</h3>
<p>You can engage consultants at any stage of your business, but the initial phase of a company formation or the start of a project, such as placing new products or services on the market is certainly the best time to do it.</p>

<p><strong>As a rule, progress in business is faster and safer with consulting.</strong></p>
<p>Through consulting, you will be able to see the circumstances that you will face during your business as your own potential in relation to competition, 
    as well as the interest of your target group for the project. The assessment of the resources you need to invest in order to achieve the goal as well 
    as the consideration of legal regulations that can affect business in the short and long term are also items that will be much easier to reflect with 
    good consulting. Consultants can more objectively recognize the advantages and disadvantages of your market access.</p>
<p><strong>It is not said without the reason that the information and advice of the experts is worth a treasure. </strong></p>

<p><strong>Fill out the contact form or simply call us. We answer within 24 hours on weekdays.</strong></p>
<Link to="/contact" className="button gold is-icon-back is-arrow-right">Make an appointment<span class="icon arrow"></span></Link>
<a href="tel:+38761787346" className="line">+387 61 787 346</a>

<br /><br /><br />
<h3>OUR CLIENTS (CONSULTING / BRANDING)</h3>

<div className="columns is-multiline consulting-clients">
    <div className="column is-2">
        <a href="https://akern.at/" target="_blank"><img src={akern} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://cochebox.com/" target="_blank"><img src={coche_box} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://sarayresidence.ba/" target="_blank"><img src={sarayresidence} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://photoschweda.at/" target="_blank"><img src={photoschweda} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://swissjewellery.ba" target="_blank"><img src={swiss} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="https://modroizeleno.com/" target="_blank"><img src={modroizeleno} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="https://kupitehniku.ba/" target="_blank"><img src={kupitehniku} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="https://www.labconsulting.at/" target="_blank"><img src={labconsulting} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://bauwach.com/" target="_blank"><img src={bauwach} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="https://zeljo.at" target="_blank"><img src={zeljo} alt="Partner 1" /></a>
    </div>
</div>
                </div>
                </div>            
</section>
        </div>
        </div>
          <Footer/>
      </Layout>
    )
  }
}


export default ConsultingPage